<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="3"></ucMenu>
					<div class="right_con">
						<div class="goods_main" >
							<p class="fb">售后管理</p>
							<div class="select_wrap">
								<el-select class="w290" v-model="searchData.shop_id" placeholder="请选择" clearable>
									<p slot="prefix" class="label_title">店铺名</p>
									<el-option v-for="item in arrShop" :key="item.key" :label="item.key" :value="item.val">
									</el-option>
								</el-select>
								<el-input class="w290" v-model="searchData.order_no" placeholder="请输入订单编号" clearable>
									<p slot="prefix" class="label_title">订单编号</p>
								</el-input>
								<el-input class="w290" v-model="searchData.goods_id" placeholder="请输入商品ID" clearable>
									<p slot="prefix" class="label_title">商品ID</p>
								</el-input>
								<el-select class="w290" v-model="searchData.after_sale_status" placeholder="请选择" clearable>
									<p slot="prefix" class="label_title">售后状态</p>
									<el-option v-for="item in arrAfterSales" :key="item.val" :label="item.text" :value="item.val">
									</el-option>
								</el-select>
								<el-button plain size="small" @click="getList()">查询</el-button>
								<el-button plain size="small" @click="resetSearch()">重置</el-button>
							</div>
							<div class="tabs_wrap" style="border-bottom: none;">
								<p class="tab" :class="searchData.type == 1 ? 'active' : ''" @click="changeType(1)">退货</p>
								<p class="tab" :class="searchData.type == 2 ? 'active' : ''" @click="changeType(2)">换货</p>
							</div>
							<div class="tabs_wrap">				
								<p class="tab" :class="searchData.status==s.val?'active':''" v-for="(s,i) in arrStatus" :key="i" @click="changeStatus(s.val)">{{s.text}}</p>
							</div>
							<div class="list_title">
								<p class="t1">货品信息</p>
								<p class="t2">售价/数量</p>
								<p class="t3">售后处理状态</p>
								<p class="t4">退款金额</p>
								<p class="t5">买家/收货人</p>
								<p class="t6">采购状态</p>
								<p class="t7">操作</p>
							</div>
							<ul class="order_list">
								<li v-for="(item,index) in list" :key="index">
									<div class="order_nums">
										<p class="num" >
											<span class="shop_name">{{ item.shop_name }}</span>售后单号：{{ item.aftersale_id }}   
											<span style="padding-left: 10px;">订单编号：{{ item.order_id }}</span>											
											<i class="iconfont icon-fuzhi" @click="bindCopy(item.order_id)"></i>
										</p>						
										<p class="date">下单时间：{{item.apply_time}}</p>
									</div>
									<div class="order_info">
										<div class="order_product">
											<div class="product_item" v-for="product in item.product" :key="product.id">
												<div class="goods_info">
													<div class="img_box">
														<el-image
															:src="product.product_pic"
															:preview-src-list="[product.product_pic]"
														/>
														<!--                              <img class="coverimg" :src="product.product_pic" />-->
													</div>
													<div class="con_box">
														<p class="title" @click="toProductDetail(product.goods_id)">{{product.product_name}}</p>
														<p class="t2">{{product.spec_value}}</p>
														<p class="t2">货号：{{product.huohao}}</p>
													</div>
												</div>
												<div class="goods_num">
													<p class="t1">{{product.item_amount}}</p>
													<p class="t2">x{{product.num}}</p>
												</div>
												<div class="goods_sales">
													<p>{{item.status}}</p>
													
												</div>
											</div>
										
										</div>
										<div class="goods_pay" v-for="product in item.product" :key="product.id">
											<p class="t1">￥{{product.refund_amount}}</p>
										
										
										</div>
										<div class="goods_buyer">
										 <p>{{item.order_address.mask_name}}</p> 
											 <p>{{item.order_address.mask_phone}}</p> 
										<p>{{item.order_address.province}}{{item.order_address.city}}{{item.order_address.town}}{{item.order_address.mask_detail}}
												
													<el-tooltip class="item" effect="dark" content="点击查看" placement="top">
														<i class="iconfont el-icon-view" @click="showAddressInfo(item.order_address.order_id)"></i>
													</el-tooltip>
												</p> 
										</div>
										<div class="goods_status" v-for="product in item.product" :key="product.id">
											<p>{{product.purchase}}</p>
										</div>
										<div class="goods_status">											
											<p><a :href="getDetailUrl(item.id)" class="details" target="_blank">订单详情</a></p>
										</div>
									</div>
									<!-- <div class="order_remarks">
										<div class="remarks_item">
											<p class="txt">卖家备注：</p>
											<p class="remarks">{{item.seller_words}}</p>
										</div>
										<div class="remarks_item">
											<p class="txt">客服备注：</p>
											<p class="remarks">{{item.service_words}}
												<i class="iconfont el-icon-edit" @click="showRemark2(item.order_address.order_id)"></i>
											</p>
										</div>
									</div> -->
								</li>
							</ul>
							<div class="turn_page">
								<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20,50,100,200,500,1000]" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 收货地址信息弹窗 -->
			<orderAddress @close="dialog.addressDialog = false" :isLoading.sync="isLoading" :show.sync="dialog.addressDialog" :order_id.sync="order_id"></orderAddress>
			<!-- 标记备注弹窗 -->
			<orderRemark @close="dialog.remarkDialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.remarkDialog" :order_id.sync="order_id"></orderRemark>
			<!-- 售后详情弹窗 -->
			<el-dialog title="售后详情" :visible.sync="dialog.aftersaleDialog" width="70%" center >
				<orderAfterSales @close="dialog.aftersaleDialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.aftersaleDialog" :info.sync="order_product"></orderAfterSales>
			</el-dialog>
			<!-- 客服备注弹窗 -->
			<serviceRemark @close="dialog.remark2Dialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.remark2Dialog" :order_id.sync="order_id"></serviceRemark>
			<!-- 设置异常弹窗 -->
			<orderAbnormal @close="dialog.abnormalDialog = false" @complete="refresh()" :isLoading.sync="isLoading" :show.sync="dialog.abnormalDialog" :order_ids="checkList"></orderAbnormal>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import orderAddress from '../../components/orderAddress/orderAddress';
import orderRemark from '../../components/orderRemark/orderRemark';
import orderAbnormal from '../../components/orderAbnormal/orderAbnormal';
import serviceRemark from '../../components/serviceRemark/serviceRemark';
import orderAfterSales from '../../components/orderAfterSales/orderAfterSales';
import orderPay from '../../components/orderPay/orderPay';
import syncOrder from '../../components/syncOrder/syncOrder';
import Pagination from '../../components/pagination/pagination';
import { Loading } from 'element-ui';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "ucenterOrders",
	data() {
		return {
			order_id: 0,
			options: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			arrStatus:[],
			shopTypeList: [],
			arrStar:[],
			arrShop:[],
			arrAbnormal:[],
			arrAfterSales:[],
			arrLackType:[],
			purchase_statusList:[
				{val:1,text:'待采购'},
				{val:2,text:'已采购'},
			],
			isLoading:false,
			list:[],
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			searchData: {
				shop_id: '',
				order_no: '',
				goods_id: '',
				after_sale_status: '',
				type: 1,
			},
			checkList: [],
			dialog: {
				addressDialog:false,
				aftersaleDialog:false,
				remarkDialog:false,
				remark2Dialog:false,
				abnormalDialog: false,
			},
			order_product:[],
			loading_service_id: 0,
		}
	},
	components:{
		myHead,myFoot,ucMenu,orderAddress,orderAfterSales,orderRemark,serviceRemark,orderPay,Pagination,syncOrder,orderAbnormal
	},
	created() {
		var query = this.$route.query
		if(query.status){
			this.searchData.status = Number(query.status)
		}
		if(query.after_sale){
			this.searchData.after_sale_status = Number(query.after_sale)
		}
		
		// 获取筛选数据后设置默认状态
		this.getOrderManageFilterData({data:{}, success:(res)=>{
			if(res.code===200){
				this.arrStatus = res.data.arr_status
				this.arrStar = res.data.arr_star
				this.arrAbnormal = res.data.arr_abnormal
				this.arrAfterSales = res.data.arr_after_sales
				this.arrShop = res.data.arr_shop
				this.shopTypeList = res.data.shop_types
				this.arrLackType = res.data.arr_lack_type
				
				// 如果没有通过 URL 传入 status 参数，则设置第一个选项为默认值
				if(!query.status && this.arrStatus.length > 0) {
					this.searchData.status = this.arrStatus[0].val
				}
				
				// 获取列表数据
				this.getList()
			}
		}})
	},
	methods: {
		...mapActions({
			getOrderManageList: "ucenter/getAfterSaleList",
			getOrderManageFilterData: "ucenter/getAfterSaleFilterData"
		}),
		initFilterData(){
			this.getOrderManageFilterData({data:{}, success:(res)=>{
				if(res.code===200){
					this.arrStatus = res.data.arr_status
					this.arrStar = res.data.arr_star
					this.arrAbnormal = res.data.arr_abnormal
					this.arrAfterSales = res.data.arr_after_sales
					this.arrShop = res.data.arr_shop
					this.shopTypeList = res.data.shop_types
					this.arrLackType = res.data.arr_lack_type
				}
			}})
		},
		//重置搜索
		resetSearch(){
			this.searchData = {
				shop_id: '',
				order_no: '',
				goods_id: '',
				after_sale_status: '',
				type: 1,
			}
			this.getList()
		},
		//刷新列表
		refresh(){
			this.page_data.page = 1
			this.getList()
		},
		changeStatus(val){
			this.searchData.status = val
			this.refresh()
		},
		changeType(val) {
			this.searchData.type = val
			this.refresh()
		},
		getList(showLoading = true){
			if(showLoading) this.isLoading = true
			let loadingInstance = Loading.service({ fullscreen: true });

			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			
			this.getOrderManageList({data:param,success:(res)=>{
				// 无论成功失败都要关闭 loading
				this.isLoading = false
				loadingInstance.close();
				
				if(res.code===200){
					this.list = res.data.data
					this.page_data.total = res.data.total
					this.page_data.page = res.data.current_page
				} else {
					this.$message.error(res.msg)
				}
			}}).catch(err => {
				// 发生错误时也要关闭 loading
				this.isLoading = false
				loadingInstance.close();
				this.$message.error('获取数据失败')
			})
		},
		showAddressInfo(id){
			this.order_id = id
			this.dialog.addressDialog = true
		},
		showRemark(id){
			this.order_id = id
			this.dialog.remarkDialog = true
		},
		showRemark2(id){
			this.order_id = id
			this.dialog.remark2Dialog = true
		},
		showAfterSaleInfo(order_product){
			this.order_product = order_product
			this.dialog.aftersaleDialog = true
		},
		showPurchaseMsg(order_product){
			this.$alert(order_product.purchase_msg, '采购状态', {
				type: 'warning',
				center: true
			});
		},
		bindCopy(text){
			var str = ''
			var input = document.createElement("input"); // 创建input对象
			input.value = text; // 设置复制内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand("Copy"); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$message({
				message: '复制成功',
				type: 'success'
			});
			
		},
		purchase_cancel(order_id){
			let that = this
			let param = {order_id:order_id}
			that.$confirm('确认取消操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				if(!that.isLoading){
					that.isLoading = true
					that.opOrderManagePurchaseCancel({data:param,success:(res)=>{
						that.isLoading = false
						if(res.code == 200){
							that.$message({
								type: 'success',
								message: res.msg
							})
							that.refresh()
						}else{
							that.$message({
								type: 'error',
								message: res.msg
							})
						}
					}})
				}
				
			}).catch((res) => {
				console.log(res)
				that.isLoading = false
			});
		},
		order_cancel(row){
			let that = this
			if(row.purchase_cancelable){
				that.$message.warning('请先取消采购！');
			}else{
				let param = {order_id:row.order_id}
				that.$confirm('确认取消操作?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					that.opOrderManageOrderCancel({data:param,success:(res)=>{
						if(res.code == 200){
							that.$message({
								type: 'success',
								message: res.msg
							})
							that.refresh()
						}else{
							that.$message({
								type: 'error',
								message: res.msg
							})
						}
					}})
				})
			}
		},
		bindReceipt(order_id){
			let that = this
			let param = {order_id:order_id}
			that.$confirm('确认收货操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				that.opOrderManageReceipt({data:param,success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
			}).catch((res) => {
				console.log(res)
			});
		},
		bindPurchase(order_id){
			this.postPurchcase(order_id)
		},
		postPurchcase(ids){
			let that = this
			let param = {ids:ids}
			that.$confirm('确认采购操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				that.opOrderManageBatPurchase({data:param,success:(res)=>{
					if(res.code == 200){
						that.$message({
							type: 'success',
							message: res.msg
						})
						that.refresh()
					}else{
						that.$message({
							type: 'error',
							message: res.msg
						})
					}
				}})
			}).catch((res) => {
				console.log(res)
			});
		},
		toProductDetail(id){
			if(id > 0){
				window.open(`/detail?id=${id}`)
			}
		},
		getDetailUrl(id) {
			const statusParam = this.searchData.status === this.arrStatus[0].val ? 1 : 2;
			return `/uc_order_aftersale?order_id=${id}&status=${statusParam}`;
		},
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;background: #fff;}
.goods_main{padding: 36px 40px 46px;min-height: 70vh;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.select_wrap .label_title .icon-shuoming{font-size: 12px;color: #999;margin-left: 2px;}
.tabs_wrap{width: 100%;height: 60px;border-bottom: 1px solid #eee;display: flex;overflow-x: scroll;overflow-y:hidden;user-select: none;padding-bottom: 6px;}

.tabs_wrap::-webkit-scrollbar {height: 8px;}
.tabs_wrap::-webkit-scrollbar-track {background: #fff;border-radius: 10px;}
.tabs_wrap::-webkit-scrollbar-thumb {background: rgba(144,147,153,.2);border-radius: 10px;}
.tabs_wrap::-webkit-scrollbar-thumb:hover {background: rgba(144,147,153,.4);}

.tabs_wrap .tab{height: 100%;padding: 30px 16px 0;position: relative;line-height: 1;cursor: pointer;font-size: 14px;color: #666;box-sizing: border-box;display: flex;justify-content: center;align-items: center;flex-shrink: 0;padding-bottom: 10px;}
.tabs_wrap .tab.active{font-weight: bold;color: var(--red);}
.tabs_wrap .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.tabs_wrap .tab .iconfont{font-size: 14px;color: #1966ff;margin-left: 4px;}
.list_title{
	width: 100%;
	height: 50px;
	background: #f9f9f9;
	margin-top: 10px;
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #666;
	line-height: 1;
}
.list_title .t1{margin-left: 20px;width: 254px;}
.list_title .t2{margin: 0 20px;width: 52px;text-align: right;}
.list_title .t3{margin: 0 2px;width: 90px;text-align: left;}
.list_title .t4{margin-left: 10px;width: 87px;text-align: right;}
.list_title .t5{margin: 0 20px;width: 132px;text-align: left;}
.list_title .t6{margin: 0 20px 0 0;flex: 1;text-align: left;}
.list_title .t7{margin: 0 20px 0 0;flex: 1;text-align: right;}
.order_list li{margin-top: 10px;font-size: 12px;}
.order_list li .order_nums{
	width: 100%;
	height: 40px;
	background: #fff6f7;
	display: flex;
	align-items: center;
	position: relative;
}
.order_list li .order_nums .btn-remark{position: absolute;right: 20px;cursor: pointer;font-size: 14px;}
.order_list li .order_nums .num{display: flex;align-items: center;margin-left: 12px;color: #666666;}
.order_list li .order_nums .num .iconfont{cursor: pointer;font-size: 14px;color: #666;margin-left: 8px;}
.order_list li .order_nums .date{margin-left: 26px;color: #666666;}
.order_list li .order_nums .maoli{margin-left: 30px;color: #666666;}
.order_list li .order_info{padding: 20px 0;border-bottom: 1px dashed #eee;display: flex;align-items: flex-start;line-height: 1;}
.order_list li .order_info .order_product{display: felx;flex-direction: column;}
.order_list li .order_info .order_product .product_item{display: flex;padding-bottom: 20px;}
.order_list li .order_info .order_product .product_item:last-child{padding-bottom: 0;}
.order_list li .order_info .goods_info{width: 242px;display: flex;margin-left: 20px;}
.order_list li .order_info .goods_info .img_box{width: 48px;height: 48px;background-color: #eeeeee;border-radius: 6px;overflow: hidden;}
.order_list li .order_info .goods_info .con_box{flex: 1;width: 0;margin-left: 10px;}
.order_list li .order_info .goods_info .con_box .title{line-height: 16px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.order_list li .order_info .goods_info .con_box .t1{color: #999999;margin-top: 12px;line-height: 14px;}
.order_list li .order_info .goods_info .con_box .t2{color: #999999;margin-top: 8px;}
.order_list li .order_info .goods_num{margin: 0 20px;width: 60px;text-align: right;}
.order_list li .order_info .goods_num .t1{color: #333333;}
.order_list li .order_info .goods_num .t2{color: #999999;margin-top: 10px;}
.order_list li .order_info .goods_sales{margin: 0 10px;width: 90px;text-align: left;}
.order_list li .order_info .goods_sales>p{line-height: 18px;color: #333;}
.order_list li .order_info .goods_sales>p.red{color: var(--red);cursor: pointer;}
.order_list li .order_info .goods_sales>p.warning{color: #f0b92c;}
.order_list li .order_info .goods_pay{margin-left: 20px;width: 60px;text-align: right;}
.order_list li .order_info .goods_pay .t1{color: #333333;}
.order_list li .order_info .goods_pay .t2{color: #333333;margin-top: 10px;}
.order_list li .order_info .goods_pay .t3{color: #666666;margin-top: 20px;}
.order_list li .order_info .goods_pay .t4{color: #666666;margin-top: 10px;}
.order_list li .order_info .goods_buyer{margin: 0 20px;width: 150px;text-align: left;}
.order_list li .order_info .goods_buyer>p{line-height: 18px;color: #666;}
.order_list li .order_info .goods_buyer>p .iconfont{cursor: pointer;font-size: 12px;color: #666;margin-left: 8px;}
.order_list li .order_info .goods_status{margin: 0 20px 0 0;flex: 1;text-align: left;}
.order_list li .order_info .goods_status:last-child{text-align: right;}
.order_list li .order_info .goods_status>p{margin-bottom: 10px;}
.order_list li .order_info .goods_status .details{color: red;cursor: pointer;}
.order_list li .order_info .goods_status .details:hover{text-decoration: underline;}
.order_list li .order_info .goods_status p>a{color: #f0413e;cursor: pointer;}
.order_list li .order_info .goods_status p>a:hover{text-decoration: underline;}
.order_list li .order_remarks{padding: 16px 0;border-bottom: 1px solid #eee;display: flex;align-items: center;justify-content: space-between;}
.order_list li .order_remarks .remarks_item{display: flex;flex: 1;}
.order_list li .order_remarks .remarks_item.abnormal{width: 100px;flex: unset;text-align: right;color: #f0b92c;display: flex;align-items: center;justify-content: flex-end;padding-right: 10px;font-weight: bold}
.order_list li .order_remarks .txt{font-size: 12px;color: #999999;line-height: 18px;width: 78px;box-sizing: border-box;padding-left: 10px;}
.order_list li .order_remarks .remarks{flex: 1;width: 0;font-size: 12px;color: #333;line-height: 18px;}
.turn_page{display: flex;justify-content: flex-end;margin-top: 30px;}
.order_list li .order_remarks .remarks i{cursor: pointer;}
.order_list .shop_name{max-width: 200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;margin-right: 5px;}
.tabs_wrap + .tabs_wrap {
	margin-top: 10px;
	border-top: none;
}
</style>
<style>
.has-time .el-button{padding: 7px 15px;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner{padding-left: 76px !important;height: 34px !important;line-height: 34px !important;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}

.el-date-editor .el-range-separator{line-height: 26px;}
.el-date-editor{width:290px !important;height: 34px !important;line-height: 34px !important;font-size: 12px !important;margin-top: 14px;}
.el-range-editor.is-active, .el-range-editor.is-active:hover{border-color: var(--red);}
.el-date-table td.end-date span, .el-date-table td.start-date span{background-color: var(--red);}
.el-date-table td.today span{color: var(--red);}
.el-picker-panel__icon-btn:hover,.el-date-table td span:hover,.el-date-table td div:hover,.el-date-table td.available:hover,.el-picker-panel__shortcut:hover{color: var(--red);}

.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}
.el-input__icon{line-height:unset;}
.el-button--primary,.el-button--primary:hover{background-color: var(--red) !important;border-color: var(--red) !important;color: #fff !important;}
.el-button:hover{border-color: var(--red) !important;background-color: rgba(240,65,62,0.1) !important;color: var(--red) !important;}
</style>
